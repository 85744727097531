@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700;900&display=swap");
@import url("https://use.typekit.net/qkq0pjp.css");

html {
  background-color: #111111;
}

* {
  padding: 0;
  margin: 0 auto;
}
button {
  color: #a0a0a0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  background-color: #111111;
  border: 2px solid #a0a0a0;
  padding: 1rem;
  width: fit-content;
  transition: 200ms;
  margin: 0;
}

button:hover {
  cursor: pointer;
  color: #a0a0a0;
  background-color: #a0a0a0;
  color: #111111;
  transition: 200ms;
}

.App {
  display: block;
}

.App-container {
  margin: 0 2rem;
  height: 60rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  color: white;
  padding-top: 1rem;
  margin-bottom: 4rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* React ^^^  */

.logo {
  font-family: eurostile-extended, sans-serif;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  font-size: 1rem;
}

.nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.desktop-only {
  display: none;
}

.mobile-only {
  position: absolute;
  justify-self: end;
  align-self: center;
  justify-content: right;
  text-align: right;
  background-color: rgba(17, 17, 17, 0.8);
}

.mobile-nav-margin {
  margin-top: 13.1rem;
}

.nav-icon {
  margin-top: 0.5rem;
  justify-self: right;
}
.nav-icon:hover {
  cursor: pointer;
}
.nav-item {
  width: 100%;
  margin: 0;
  justify-self: end;
  padding: 0 0.5rem 0 0;
  color: white;
}
.nav-item:hover {
  cursor: pointer;
  color: #111111;
  background-color: white;
}
.nav-item-text {
  color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.nav-item-text:hover {
  color: #111111;
}
.nav-links {
  text-decoration: none;
  list-style: none;
  padding: 0;
  text-align: right;

  padding: 1rem;
}
.page-button {
  color: #a0a0a0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  border: 2px solid #a0a0a0;
  padding: 1rem;
  width: fit-content;
  transition: 200ms;
  margin: 0;
}
.page-button:hover {
  cursor: pointer;
  background-color: #a0a0a0;
  color: #111111;
}
.page-button-centered {
  color: #a0a0a0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  border: 2px solid #a0a0a0;
  padding: 1rem;
  width: fit-content;
  transition: 200ms;
  margin: 0 auto;
}
.page-button-centered:hover {
  cursor: pointer;
  background-color: #a0a0a0;
  color: #111111;
}
.contact-send {
  background: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.img-container {
  margin-bottom: 1rem;
  width: 100%;
}
.img {
  width: 100%;
}
.ul {
  list-style: none;
  color: white;
}
.li {
  margin-bottom: 3.5rem;
  font-family: "Roboto", sans-serif;
}
.h1 {
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  font-weight: 900;
  color: white;
  margin-bottom: 1rem;
}
.h1-small {
  font-size: 2rem;
}
.h2 {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  color: white;
  margin-bottom: 1rem;
}
.p {
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  color: white;
  margin-bottom: 1.5rem;
}
.p-large {
  font-size: 2rem;
}
.p2 {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  color: white;
  margin-bottom: 1.5rem;
}
.p3 {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  color: white;
  margin-bottom: 1.5rem;
}
.email {
  color: white;
  text-decoration: none;
  line-height: 1.5;
}
.bottom-margin {
  margin-bottom: 3rem;
}
.footer {
  text-align: center;
  margin-top: 6rem;
  padding-bottom: 1.5rem;
}
.footer-item {
  margin-bottom: 2.5rem;
}
.footer-item:hover {
  cursor: pointer;
}
.euro-text {
  font-family: eurostile-extended, sans-serif;
  font-weight: 400;
}
.sample-link:hover {
  transition: 300ms;
  cursor: pointer;
  padding: 1rem;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 2.5rem;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.react-icons {
  margin-right: 0.6rem;
}
.form-label {
  color: white;
}
.form-label {
  display: block;
  margin-bottom: 0.5rem;
}
.form-label-small {
  display: block;
  color: white;
  font-size: 0.9rem;
  justify-self: left;
}
.form-input {
  display: inline;
  width: 100%;
  margin-bottom: 0.2rem;
  height: 2.5rem;
  border-radius: 2px;
  padding-left: 0.7rem;
  border: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.form-names {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.6rem;
  margin-bottom: 1.8rem;
}
.email-form {
  margin-bottom: 1.8rem;
}
.message-form {
  margin-bottom: 1.8rem;
}
.message-input {
  display: block;
  width: 100%;
  height: 6rem;
  padding-left: 0.7rem;
  padding-top: 0.4rem;
  border-radius: 2px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.name-column {
  width: 100%;
}
.contact-info {
  margin-bottom: 3rem;
  width: 100%;
  max-width: 43rem;
}
.contact-form {
  width: 100%;
}
.form-container {
  max-width: 43rem;
}
.main-grid {
  display: grid;
  grid-template-columns: 1fr;
}
.main-grid.mixing-grid {
  margin-bottom: 1rem;
}
.mixing-button-contact {
  display: block;
  margin: 0 auto;
  width: 50%;
  margin-bottom: 15rem;
  justify-self: center;
}
ul.footer {
  display: grid;
  grid-template-columns: 1fr;
}
.social-links-desktop {
  display: none;
}
.social-links-mobile {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content;
  margin-top: 1rem;
}
.social-links-container {
  width: max-content;
  margin: 0;
}
.nav-links-container {
  display: grid;
  grid-template-columns: 1fr;
  text-align: end;
  justify-items: end;
  justify-content: end;
  margin: 0;
}
.hr {
  margin-bottom: 2rem;
  margin-top: 2rem;
  height: 1px;
  background-color: rgb(71, 71, 71);
  border: none;
}
.samples-page {
  margin: 0 2rem 0 2rem;
}
.sounddesign-page {
  max-width: 50rem;
  margin-top: 0;
}
.lessons-page {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 30rem;
  width: 100%;
  margin: 0 auto 0 auto;
}
.about-page {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 30rem;
  width: 100%;
  margin: 0 auto 0 auto;
}
.sample-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 2rem 0 2rem;
}
.contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: 0;
  justify-content: stretch;
  justify-items: stretch;
}
@media only screen and (min-width: 22.6rem) {
  .logo {
    font-size: 1.5rem;
  }
  .na .nav-icon {
    margin-top: 0.5rem;
  }
}
@media only screen and (min-width: 33.6rem) {
  .logo {
    font-size: 2.5rem;
  }
  .nav-icon {
    margin-top: 0.9rem;
  }
  .sample-grid {
    margin: 0 4rem 0 4rem;
  }
  .samples-grid {
    margin: 0 4rem 0 4rem;
  }
}
@media only screen and (min-width: 41.5rem) {
  .main-grid {
    margin: 0 7rem 10rem 7rem;
  }
}
@media only screen and (min-width: 61.2rem) {
  .nav {
    height: 6rem;
  }
  .desktop-only {
    display: grid;
  }
  .mobile-only {
    display: none;
  }
  .social-links-mobile {
    display: none;
  }
  .social-links-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
    justify-self: end;
    width: 4rem;
    margin: 0;
  }
  .sounddesign-page {
    margin-top: -4rem;
  }
  .lessons-page {
    max-width: 78rem;
    margin: 0 auto 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
    margin-top: -4rem;
  }
  .about-page {
    max-width: 78rem;
    margin: 0 auto 0 auto;
    grid-template-columns: 1fr 1.5fr;
    grid-column-gap: 3rem;
    margin-top: -4rem;
  }
  .img-container.img-lesson {
    margin-bottom: 0.7rem;
  }
  .nav-links {
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    text-decoration: none;
    list-style: none;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    font-size: 1rem;
  }
  .nav-links-text {
    width: 100%;
    padding: 0;
    margin: 0;
    justify-self: center;
    text-align: center;
  }
  .nav-links-container {
    grid-template-columns: max-content max-content;
    grid-column-gap: 2rem;
    margin-left: 6rem;
  }
  .samples-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2rem;
    margin: 0;
  }
  ul.footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 15rem 0 15rem;
  }
  .nav-item {
    width: 100%;
    padding: 0 0.25rem 0 0.25rem;
    margin: 0;
    justify-self: center;
    text-align: center;
    align-self: center;
    color: white;
  }
  .App-header {
    margin-bottom: 8rem;
  }
  .main-grid {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6rem;
    margin: 0 5rem 10rem 5rem;
    justify-items: end;
    align-items: center;
    margin-bottom: 10rem;
  }
  .img-container {
    width: 100%;
    justify-self: end;
    margin-bottom: 0;
  }
  .mixing-grid {
    margin: 0 5rem 0 5rem;
    grid-template-columns: 1.6fr 2fr;
  }
  .mixing-h {
    align-self: normal;
  }
  .mixing-p {
    align-self: normal;
  }
  .sample-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 7rem 0 7rem;
    grid-column-gap: 3rem;
  }
  .contact-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (min-width: 75rem) {
  .main-grid {
    margin: 0 10rem 10rem 10rem;
  }
  .nav {
    width: 65rem;
  }
  .social-links-desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1.4rem;
  }
}
@media only screen and (min-width: 85.3rem) {
  .main-grid {
    margin: 0 15rem 10rem 15rem;
  }
  .mixing-grid {
    margin: 0 10rem 0 10rem;
    grid-template-columns: 1.6fr 2fr;
  }
  ul.footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 30rem 0 30rem;
  }
  .nav {
    width: 75rem;
  }
}
@media only screen and (min-width: 98.8rem) {
  .h1 {
    font-size: 3rem;
  }
  .p {
    font-size: 2rem;
  }
  .h2 {
    font-size: 2.5rem;
  }
  .p2 {
    font-size: 1.7rem;
  }
  .nav {
    width: 90rem;
  }
  .nav-links-container {
    grid-template-columns: max-content max-content max-content max-content;
    grid-column-gap: 2rem;
    margin-left: 6rem;
    margin-top: 0.3rem;
  }
  .sample-grid {
    margin: 0 15rem 0 15rem;
  }
  .samples-page {
    margin: 0 10rem 0 10rem;
  }
}
@media only screen and (min-width: 124.8rem) {
  .main-grid {
    margin: 0 30rem 10rem 30rem;
  }
  .nav {
    width: 106rem;
  }
  .samples-page {
    margin: 0 20rem 0 20rem;
  }
}
